export const styles = `
<style>
.container {
  display: grid;
  padding: 15px;
  justify-items: center;
  align-content: center;
}

.text-contents {
  align-self: center;
  justify-self: center;
  color: #005f73;
}

.heading {
  color: #ff0000;
}

.paragraph {
}

p,
h1 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 600px;
}
</style>`;
