import {useState } from 'react'

const App = ({text = 'The button wasn\'t pressed yet.', children, onClick, hash}) => {
  const [date] = useState(new Date());
  
  return (
    <div className="container">
      <div className="text-contents">
        <h1 className="heading">POC OLF Custom Element</h1>
        <p className="paragraph">Hash parameter value = {hash}</p>
      </div>
      <img src="https://olf-web-components.dev.eon.cz/assets/images/404-NotFound.png" alt="" />
      <div>
        This is a custom element, created at {date.toString()}
        <br/>
        {text}
        <br/>
        <button onClick={onClick}>Click me!</button>
        <br/>
        {children}
      </div>
    </div>
  );
};

export default App;
